import '../styles/app.scss';
import '../styles/modal.css';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import idl from '../data/idl.json';

import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { useWallet, useAnchorWallet , WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from 'react-toastify';
import posthog from 'posthog-js';

import { initializerFilter, receiverFilter, SmartContractConnector } from '../utils/smartContractConnector';
import { getSOLFromLamports } from "../utils/utils";
import {Header, Footer, NewTransfer, Transfer, TransferDetail} from '../components';

require('@solana/wallet-adapter-react-ui/styles.css');
if (!window.location.href.includes('127.0.0.1')) {
  posthog.init("phc_FKBKTlTsIaPsPj1rmbNDGIcGqwX26AtohO67ZAj7sdn", {api_host: 'https://app.posthog.com'})
}

// Configs
const wallets = [
  new PhantomWalletAdapter()
];
const networkURL = "https://api.devnet.solana.com";
const connector = new SmartContractConnector(networkURL, idl);

const Home = () => {

  const [_refresh, refresh] = useState(0);

  const wallet = useWallet();
  const anchorWallet = useAnchorWallet();

  const [balance, setBalance] = useState(null);
  const [received, setReceived] = useState(null);
  const [initialized, setInitialized] = useState(null);

  const [newIsOpen, setnewOpen] = useState(false);
  const [isWaitingTransaction, setWaitingTransaction] = useState(false);

  const [detailIsOpen, setDetailOpen] = useState(false);
  const [detailData, setDetailData] = useState({})

  useEffect(() => {
    if(wallet.connected) {
      connector.fetchTransfers(wallet, receiverFilter, setReceived);
      connector.fetchTransfers(wallet, initializerFilter, setInitialized);
      connector.getBalance(wallet, setBalance)
    }
  }, [wallet, _refresh])

  const refetch = () => {refresh(_refresh+1)}

  const toogleDetails = (value, data = detailData) => {
    setDetailData(data)
    setDetailOpen(value)
  }

  const waitTransaction = async (transaction) => {
    setWaitingTransaction(true);
    try {
      const txid = await transaction();
      const shortTxid = `${txid.substring(0,5)}[......]${txid.substring(txid.length-5,txid.length)}`
      console.log(`Transaction ID: ${txid}`)
      toast.success(
        <p>Success!<br/> Transaction: <a className="tx-link" href={`https://explorer.solana.com/tx/${txid}?cluster=devnet`}target="_blank">{shortTxid}</a></p>,
        {autoClose: false}
      );
    }
    catch(e) {
      console.log(e.message)
      toast.error(e.message);
    }
    finally {
      setWaitingTransaction(false);
      refetch();
    }
  }

  // Rendering
  const TransferList = ({list, received, wallet}) => {
    if (list === null || list.length === 0) {
      return <p className='no-transfer panel-element'>No transfers</p>
    }
    else {
      list = list.sort((a, b) => b.account.transacionDate.toNumber() - a.account.transacionDate.toNumber())
      return (  
        <div className="transfer-list">
          {list.map((transfer, i) => 
            <Transfer 
              toogleDetails = {toogleDetails}
              waitTransaction = {waitTransaction}
              connector = {connector} 
              account = {transfer.account} 
              received = {received} 
              wallet = {wallet} 
              key = {i}/>)}
        </div>
      )
    }
  }

  return (
    <div className='container'>

      <ToastContainer
        position="top-left"
        theme='dark'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover/>

    {wallet.connected ?
      <div className="main-panel">

        <Modal
          isOpen={isWaitingTransaction}
          onRequestClose={() => setnewOpen(false)}
          contentLabel="Loading transaction"
          className="modal"
          overlayClassName= "modal-overlay"
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}>
          <div className="loader-container">
            <h3>Confirming transaction...</h3>
            <ClipLoader loading={isWaitingTransaction} color="#ffffff" size={100}/>  
            <p>This can take a few seconds after approving</p>
          </div>    
        </Modal>

        <Modal
          isOpen={newIsOpen}
          onRequestClose={() => setnewOpen(false)}
          contentLabel="New transfer"
          className="modal"
          overlayClassName= "modal-overlay"
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}>
          <NewTransfer 
            wallet={anchorWallet}
            closeModal = {() => setnewOpen(false)} 
            connector = {connector} 
            waitTransaction = {waitTransaction}/>
        </Modal>

        <Modal
          isOpen={detailIsOpen}
          onRequestClose={() => toogleDetails(false)}
          contentLabel="Transfer details"
          className="modal"
          overlayClassName= "modal-overlay"
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}>
          <TransferDetail transferData={detailData} closeModal={() => toogleDetails(false)}/>
        </Modal>



        <div className='panel-section'>
          <h3>
            My wallet
            <button onClick={() => wallet.disconnect()} className ="button purple-button-e">Disconnect</button>
          </h3>
          <div className='panel-info panel-element'>
            <p className='connected-wallet'><strong>Wallet Connected : </strong><span>{wallet.publicKey.toString()}</span></p>
            <p className='connected-balance'><strong>Balance : </strong>{getSOLFromLamports(balance)} SOL</p>
          </div>
        </div>
        
        <div className='main-panel-operations'>

          <div className='panel-section' >
            <h3>
              Initialized transfers 
              <button onClick={() => setnewOpen(true)} className ="button purple-button">New transfer</button>
            </h3>
            <div className='panel-transfers'>
              <TransferList list={initialized} received={false} wallet={anchorWallet}/>
            </div>
          </div>

          <div className='panel-section'>
            <h3>Received transfers</h3>
            <div className="panel-transfers">
              <TransferList list={received} received={true} wallet={anchorWallet}/>
            </div>
          </div>

        </div>
      </div>
    :
    <div className='home-container'>      
      <div className='wallet-connection panel-element'>
        <h3>Connect your wallet to manage your transfers</h3>
        <WalletMultiButton/>
      </div>

      <div className='explanations'>
        <h2>Scheduled transfer?</h2>
        <p>
          This app allows you to create or receive scheduled SOL transfers to/from another wallet (Devnet application only).<br/>
          When initializing a transfer, the SOL are stored in a vault:
        </p>
        <ul>
          <li>Before the transaction date, the initializer of the transfer can still cancel it and get the vault content.</li>
          <li>After the transaction date, the initializer can't cancel, and the receiver can collect the vault content.</li>
        </ul>
      </div>
    </div>

    }
    </div>
  )
}

const AppWithProvider = () => (
  <ConnectionProvider endpoint={networkURL}>
    <WalletProvider wallets={wallets}>
      <WalletModalProvider>
        <Header/>
        <Home />
        <Footer/>
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
)

export default AppWithProvider;
