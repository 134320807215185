import '../styles/transferdetail.scss';

const TransferDetail = ({transferData, closeModal}) => {

    const getState = (received, transferDatePassed) => {
        if (received) {
            return transferDatePassed
             ? "Scheduled (Transfer date passed): waiting for you to withdraw."
             : "On hold (Waiting for transfer date): sender can still cancel."
        }   
        else {
            return transferDatePassed
            ? "Scheduled (Transfer date passed): waiting for receiver to withdraw."
            : "On hold (Waiting for transfer date): cancelable until transfer date."
        }
    }

    return (
        <div className="transfer-detail-container">

            <h3 className="transfer-detail-title">Transfer Details</h3>

            <div className="all-info-container">

                <div className="info-container">
                    <h3>Amount</h3>
                    <p>{transferData.amount} SOL</p>       
                </div>

                <div className="info-container">
                    <h3>{transferData.received ? "Sender" : "Receiver"} address</h3>
                    <p>{transferData.fullAddress}</p>    
                </div>

                <div className="info-container">
                    <h3>Effective on</h3>
                    <p>{transferData.dateTime}</p>   
                </div>

                <div className="info-container">
                    <h3>Transfer State</h3>
                    <p>{getState(transferData.received, transferData.transferDatePassed)}</p>   
                </div>

            </div> 

            <div className="buttons-container">
                <button className="button purple-button-e" onClick={() => closeModal()}>Close</button>
            </div>
        </div>
    )
}

export default TransferDetail;