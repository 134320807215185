import { PublicKey } from "@solana/web3.js";

// Utils
const getSOLFromLamports = (lamports) => {
    return (lamports/1000000000).toFixed(2) 
}

const formatDate = (t) => {

    const date = ('0' + t.getDate()).slice(-2);
    const month = ('0' + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();
    const hours = ('0' + t.getHours()).slice(-2);
    const minutes = ('0' + t.getMinutes()).slice(-2);
    const seconds = ('0' + t.getSeconds()).slice(-2);

    return `${date}/${month}/${year} ${hours}:${minutes}:${seconds} UTC`;
}

const isValidPubKeyFormat = (pubKey) => {
    try {
        new PublicKey(pubKey);
        return true;
    }
    catch {
        return false;
    }
}

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export {
    getSOLFromLamports,
    formatDate,
    isValidPubKeyFormat,
    sleep
}