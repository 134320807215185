const Footer = () => (

    <div className="footer">
        <p>
            <a href="https://exorth.dev" target={"_blank"}>Exorth.dev</a> creation | 
            Member of <a href="https://lab107.dev" target={"_blank"}>LAB 107</a>
        </p>
    </div>
    
);

export default Footer;