import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Connection, PublicKey, Transaction, SYSVAR_CLOCK_PUBKEY } from '@solana/web3.js';
import { BN } from "bn.js"
import bs58 from "bs58";

const { SystemProgram } = anchor.web3;

// Filters
const receiverFilter = (receiver_key) => ({
    memcmp: {
        offset: 8 + 32, // Discriminator + initializer
        bytes: bs58.encode(receiver_key.toBuffer()),
    },
});

const initializerFilter = (initializer_key) => ({
    memcmp: {
        offset: 8 , // Discriminator
        bytes: bs58.encode(initializer_key.toBuffer()),
    },
});

// Connector class
class SmartContractConnector {
    constructor(networkURL, idl, wallet) {
      this.networkURL = networkURL;
      this.idl = idl;
      this.programID = new PublicKey(idl.metadata.address);
      this.connection = new Connection(this.networkURL,"processed");
      this.provider = new AnchorProvider(this.connection, wallet, "processed");
      this.wallet = wallet;
    }

    // utils 
    async findVaultPDA(receiverKey, transferUuid) {
        return await anchor.web3.PublicKey.findProgramAddress([
            anchor.utils.bytes.utf8.encode("differed"),
            receiverKey.toBytes(),
            transferUuid.toBytes()
        ], this.programID);
    }

    async getBalance(wallet, setBalance) {
        const balance = await this.connection.getBalance(wallet.publicKey);
        setBalance(balance);
    }

    async fetchTransfers(wallet, filter, setState) {
        const program = new Program(this.idl, this.programID, this.provider);
        const transfers = await program.account.vaultAccount.all([
            filter(wallet.publicKey),
        ]);
        setState(transfers)
    }

    async signTransaction(transaction, wallet, program) {
        const {blockhash, lastValidBlockHeight} = await program.provider.connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = wallet.publicKey;

        const signed = await wallet.signTransaction(transaction);
        const txid = await this.connection.sendRawTransaction(signed.serialize());
        await this.connection.confirmTransaction(txid, {commitment:"confirmed"});

        return txid;
    }

    // Execution utils
    async initializeTransfer(wallet, receiverKey, bounty, delayInSeconds) {

        const transferUuid = anchor.web3.Keypair.generate().publicKey;
        const [vaultAccount, bump] = await this.findVaultPDA(receiverKey, transferUuid);

        const program = new Program(this.idl, this.programID, this.provider);

        const transaction = new Transaction().add(
            await program.methods
                .initialize(new BN(bounty), new BN(delayInSeconds), transferUuid)
                .accounts({
                    initializer: wallet.publicKey,
                    receiver: receiverKey,
                    vault: vaultAccount,
                    user: wallet.publicKey,
                    systemProgram: SystemProgram.programId,
                    clock: SYSVAR_CLOCK_PUBKEY
                })
                .instruction()
        );
        const {blockhash, lastValidBlockHeight} = await program.provider.connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = wallet.publicKey;

        const txid = await this.signTransaction(transaction, wallet, program);
        return txid;
    }

    async executeTransfer(wallet, transferUuid) {

        const [vaultAccount, bump] = await this.findVaultPDA(wallet.publicKey, transferUuid);
        const program = new Program(this.idl, this.programID, this.provider);

        const transaction = new Transaction().add(
            await program.methods
                .execute(transferUuid)
                .accounts({
                    clock: SYSVAR_CLOCK_PUBKEY,
                    user: wallet.publicKey,
                    vault: vaultAccount,
                })
                .instruction()
        )

        const txid = await this.signTransaction(transaction, wallet, program);
        return txid;
      }
    
    async cancelTransfer(wallet, receiverKey, transferUuid) {
    
        const [vaultAccount, bump] = await this.findVaultPDA(receiverKey, transferUuid);
        const program = new Program(this.idl, this.programID, this.provider);
    
        const transaction = new Transaction().add(
            await program.methods
                .cancel(receiverKey, transferUuid)
                .accounts({
                    clock: SYSVAR_CLOCK_PUBKEY,
                    user: wallet.publicKey,
                    vault: vaultAccount,
                })
                .instruction()
        )
        
        const txid = await this.signTransaction(transaction, wallet, program);
        return txid;
    }
}

export {
    SmartContractConnector,
    receiverFilter,
    initializerFilter
}