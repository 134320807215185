import '../styles/newtransfer.scss';
import { useState } from 'react';
import { PublicKey } from '@solana/web3.js';
import { isValidPubKeyFormat } from '../utils/utils';

import { toast } from 'react-toastify';

const checkInputs = (receiverKey, bounty, delay) => {

    if (!isValidPubKeyFormat(receiverKey)) {
        toast.warn("Invalid receiver key")
        return false;
    }

    if (bounty <= 0) {
        toast.warn("Invalid bounty, should be more than 0")
        return false;
    }

    if (delay <= 0) {
        toast.warn("Invalid date, should be in the future")
        return false;
    }

    return true
}

const NewTransfer = ({connector, wallet, closeModal, waitTransaction}) => {

    const [receiverKey, setReceiverKey] = useState("");
    const [bounty, setBounty] = useState(0);
    const [date, setDate] = useState("");
    const [time, setTime] = useState("00:00");
    const [delay, setDelay] = useState("");

    const submitTransfer = async () => {
        if (checkInputs(receiverKey, bounty, delay)) {
            const pubKey = new PublicKey(receiverKey)
            const lamportsBounty = bounty*Math.pow(10,9)
            closeModal();
            waitTransaction(async () => {
                return  await connector.initializeTransfer(wallet, pubKey, lamportsBounty, delay); 
            })
        }
    }

    const handleDate = (value, time = "") => {
        const now = Date.now()
        setDate(value)

        if (time !== ""){
            value += `T${time}:00.000+00:00`
        }
        
        const dateObj = Date.parse(value)
        const delayCal = (dateObj - now) /  1000;
        setDelay(delayCal)

    }

    const handleTime = (value) => {
        setTime(value)
        if (date !== "") {
            handleDate(date, value)
        }
    }

    return (
        <div className="new-transfer-container">

            <h3 className="new-transfer-title">New Transfer</h3>

            <div className="all-inputs-container">

                <label className="input-container">
                    Receiver wallet
                    <input type="text" name="receiver" placeholder='Wallet public key (address)' value={receiverKey} onChange={(e) => setReceiverKey(e.currentTarget.value)}></input>       
                </label>

                <label className="input-container">
                    Amount to transfer (SOL)
                    <input type="number" name="bounty" value={bounty} onChange={(e) => setBounty(e.currentTarget.value)}></input>
                </label>

                <label className="input-container">
                    Transfer effectiveness (GMT)
                    <div className='date-inputs'>
                        <input type="date" name="delay" value={date} onChange={(e) => handleDate(e.currentTarget.value)}></input>
                        <input type="time" name="delay_time" value={time} onChange={(e) => handleTime(e.currentTarget.value)}></input>
                    </div>
                </label>

            </div> 

            <div className="buttons-container">
                <button className="button red-button-e" onClick={() => closeModal()}>Cancel</button>
                <button className="button green-button" onClick={() => submitTransfer()}>Sumbit</button>
            </div>
        </div>
    )
}

export default NewTransfer;