
const Header = () => (
    <div className="header">
        <a href="https://exorth.dev" target={"_blank"}><img src='exorth.png' alt="exorth.dev"/></a>
        <h2>Solana Scheduled Transfer</h2>
        <a className="network" 
            href="https://explorer.solana.com/address/CT1SBuZEz27KXmPfXq9WutxsjfP6iKL5jXyhhRWDxgUN?cluster=devnet" 
            target={"_blank"}>
            On Devnet
        </a>
    </div>
);

export default Header;