import { getSOLFromLamports, formatDate } from "../utils/utils";

const Transfer = ({connector, account, received, wallet, waitTransaction, toogleDetails}) => {

    const amount = getSOLFromLamports(account.amount.toNumber());
    const transferTimestamp  = new Date(account.transacionDate.toNumber()*1000);
    const dateTime = formatDate(transferTimestamp);
    const transferDatePassed = transferTimestamp <= Date.now();
    const fullAddress = received ? account.initializer.toString() : account.receiver.toString();
    const address = `${fullAddress.substring(0,10)}...`

    const transferData = {received, amount, dateTime, fullAddress, transferDatePassed}

    const handleCancel = (event) => {
        event.stopPropagation();
        waitTransaction(async () => {
            return connector.cancelTransfer(wallet, account.receiver, account.uuid);
        })
    }

    const handleExecute = (event) => {
        event.stopPropagation();
        waitTransaction(async () => {
            return connector.executeTransfer(wallet, account.uuid);
        })
    }

    if (received) {
        return (
            <div className="transfer panel-element" onClick={() => toogleDetails(true, transferData)}>
                <p className="positive-amount">+ {amount} SOL</p>
                <p><strong>From:</strong> {address}</p>
                <p><strong>Effective:</strong> {dateTime}</p>
                {transferDatePassed ?
                    <button 
                        className="button green-button-e"
                        onClick={(event) => handleExecute(event)} 
                    >
                        Collect
                    </button>
                : <p className="no-action">Scheduled</p>}
            </div>
        )
    }

    else {
        return (
            <div className="transfer panel-element" onClick={() => toogleDetails(true, transferData)}>
                <p className="negative-amount">- {amount} SOL</p>
                <p><strong>To:</strong> {address}</p>
                <p><strong>Effective:</strong> {dateTime}</p>
                {!transferDatePassed ?
                    <button 
                        className="button red-button-e"
                        onClick={(event) => handleCancel(event)} 
                        >
                        Cancel
                    </button>
                : <p className="no-action">Expired</p>}
            </div>
        )
    }
}

export default Transfer;


